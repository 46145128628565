import React from "react";

// Profile
import UserProfile from "../pages/Authentication/user-profile";

// Pages Calendar
import Calendar from "../pages/Calendar/index";

//Email
import EmailInbox from "../pages/Email/email-inbox";
import EmailRead from "../pages/Email/email-read";
import EmailCompose from "../pages/Email/email-compose";

import Emailtemplatealert from "../pages/EmailTemplate/email-template-alert";
import Emailtemplatebasic from "../pages/EmailTemplate/email-template-basic";
import Emailtemplatebilling from "../pages/EmailTemplate/email-template-billing";

// Authentication related pages
import Login from "../pages/Authentication/Login";
import Logout from "../pages/Authentication/Logout";
import Register from "../pages/Authentication/Register";
import ForgetPwd from "../pages/Authentication/ForgetPassword";

//  // Inner Authentication
import Login1 from "../pages/AuthenticationInner/Login";
import Login2 from "../pages/AuthenticationInner/Login2";
import Register1 from "../pages/AuthenticationInner/Register";
import Register2 from "../pages/AuthenticationInner/Register2";
import Recoverpw from "../pages/AuthenticationInner/Recoverpw";
import Recoverpw2 from "../pages/AuthenticationInner/Recoverpw2";
import ForgetPwd1 from "../pages/AuthenticationInner/ForgetPassword";
import LockScreen from "../pages/AuthenticationInner/auth-lock-screen";
import LockScreen2 from "../pages/AuthenticationInner/auth-lock-screen-2";
import ConfirmMail from "../pages/AuthenticationInner/page-confirm-mail";
import ConfirmMail2 from "../pages/AuthenticationInner/page-confirm-mail-2";
import EmailVerification from "../pages/AuthenticationInner/auth-email-verification";
import EmailVerification2 from "../pages/AuthenticationInner/auth-email-verification-2";
import TwostepVerification from "../pages/AuthenticationInner/auth-two-step-verification";
import TwostepVerification2 from "../pages/AuthenticationInner/auth-two-step-verification-2";

// Dashboard
import Dashboard from "../pages/Dashboard/index";
// Charts
import ChartApex from "../pages/Charts/Apexcharts";
import ChartistChart from "../pages/Charts/ChartistChart";
import ChartjsChart from "../pages/Charts/ChartjsChart";
import EChart from "../pages/Charts/EChart";
import SparklineChart from "../pages/Charts/SparklineChart";

// Maps
import MapsGoogle from "../pages/Maps/MapsGoogle";
import MapsVector from "../pages/Maps/MapsVector";
import MapsLeaflet from "../pages/Maps/MapsLeaflet";

//Icons
import IconDripicons from "../pages/Icons/IconDripicons";
import IconMaterialdesign from "../pages/Icons/IconMaterialdesign";
import TypiconsIcon from "../pages/Icons/IconTypicons";
import IconIon from "../pages/Icons/IconIon";
import ThemifyIcon from "../pages/Icons/IconThemify";
import IconFontawesome from "../pages/Icons/IconFontawesome";

//Tables
import BasicTables from "../pages/Tables/BasicTables";
import DatatableTables from "../pages/Tables/DatatableTables";
import ResponsiveTables from "../pages/Tables/ResponsiveTables";
import EditableTables from "../pages/Tables/EditableTables";

// Forms
import FormElements from "../pages/Forms/FormElements";
import FormAdvanced from "../pages/Forms/FormAdvanced";
import FormEditors from "../pages/Forms/FormEditors";
import FormValidations from "../pages/Forms/FormValidations";
import FormMask from "../pages/Forms/FormMask";
import FormRepeater from "../pages/Forms/FormRepeater";
import FormUpload from "../pages/Forms/FormUpload";
import FormWizard from "../pages/Forms/FormWizard";
import FormXeditable from "../pages/Forms/FormXeditable";

//Ui
import UiAlert from "../pages/Ui/UiAlert";
import UiButtons from "../pages/Ui/UiButtons";
import UiCards from "../pages/Ui/UiCards";
import UiCarousel from "../pages/Ui/UiCarousel";
import UiColors from "../pages/Ui/UiColors";
import UiDropdown from "../pages/Ui/UiDropdown";
import UiGeneral from "../pages/Ui/UiGeneral";
import UiGrid from "../pages/Ui/UiGrid";
import UiImages from "../pages/Ui/UiImages";
import UiLightbox from "../pages/Ui/UiLightbox";
import UiModal from "../pages/Ui/UiModal";
import UiProgressbar from "../pages/Ui/UiProgressbar";
import UiTabsAccordions from "../pages/Ui/UiTabsAccordions";
import UiTypography from "../pages/Ui/UiTypography";
import UiVideo from "../pages/Ui/UiVideo";
import UiSessionTimeout from "../pages/Ui/UiSessionTimeout";
import UiRating from "../pages/Ui/UiRating";
import UiRangeSlider from "../pages/Ui/UiRangeSlider";
import UiUtilities from "pages/Ui/UiUtilities";
import UiOffcanvas from "pages/Ui/UiOffcanvas";

//Pages
import PagesStarter from "../pages/Utility/pages-starter";
import PagesMaintenance from "../pages/Utility/pages-maintenance";
import PagesComingsoon from "../pages/Utility/pages-comingsoon";
import PagesTimeline from "../pages/Utility/pages-timeline";
import PagesInvoice from "../pages/Utility/PagesInvoice";
import PagesFaqs from "../pages/Utility/pages-faqs";
import PagesPricing from "../pages/Utility/pages-pricing";
import Pages404 from "../pages/Utility/pages-404";
import Pages500 from "../pages/Utility/pages-500";
import PagesGallery from "../pages/Utility/PagesGallery";
import PagesDirectory from "../pages/Utility/PagesDirectory";
import PagesProfile from "pages/Utility/pages-profile";

import AdminList from "../pages/Settings/Admin-List"
import RoleMiddleware from "./middleware/Rolemiddleware";

import { AdminProvider } from "context/AdminProvider";
import Engineer from "pages/Admin-Engineer/Box-Management";
import UserList from "pages/Admin-Support";
import Leaderboard from "pages/Leaderboard";
import FinancePrice from "pages/Admin-Finance/PriceManagement/index";
import FinancePayment from "pages/Admin-Finance/Accounting";
import Donation from "pages/Admin-Finance/Donation";
import { BoxProvider } from "context/BoxProvider";
import { UserProvider } from "context/UserProvider";
import { LeaderProvider } from "context/LeaderProvider";
import { PaymentProvider } from "context/PaymentProvider";
import { DonationProvider } from "context/DonationProvider";
import { PriceProvider } from "context/PriceProvider";
import FinanceCurrencies from "pages/Admin-Finance/Currencies";
import { CurrencyProvider } from "context/CurrencyProvider";
import AppSettings from "pages/Settings/Mobile-App";
import BroadcastSupports from "pages/Support/PN-Broadcast";
import Pickups from "pages/Support/Pickups";
import Alerts from "../pages/Settings/Alerts"
import { BonusTierProvider } from "context/BonusTierProvider";
import { GroupProvider } from "context/GroupProvider";
import { PeriodProvider } from "context/PeriodProvider";

import Menus from "pages/Settings/Admin-List/Menu";
// import Permissions from "pages/Settings/Admin-List/Permission";
import Overview from "pages/eRP/Overview";
import Assets from "pages/eRP/Assets";
import Collections from "pages/eRP/Collections";
import Orders from "pages/eRP/Orders";
import UcoUnits from "pages/eRP/Units";
import Incidents from "pages/eRP/Incidents";
import Settings from "pages/eRP/Settings";
import { NotifLogProvider } from "context/NotificationLogProvider";
import Transactions from "pages/Support/Transactions";
import { EducardProvider } from "context/EducardProvider";
import { MenuProvider } from "context/MenuProvider";
import { PermissionProvider } from "context/PermissionProvider";
import { DailyTipProvider } from "context/DailyTipProvider";

import OTPDisabledMiddleware from "./middleware/OTPDisabledMiddleware";
import { TransactionProvider } from "context/TransactionProvider";
// import { MenuList } from "react-select/dist/declarations/src/components/Menu";
import { AssetProvider } from "context/AssetProvider";
import Group from "pages/Admin-Engineer/Groups";
import { CollectionProvider } from "context/CollectionProvider";
import { TourProvider } from "context/TourProvider";
import { OrderProvider } from "context/OrderProvider";
import { OverviewProvider } from "context/OverviewProvider";
import { UcoUnitProvider } from "context/UcoUnitProvider";
import { IncidentProvider } from "context/IncidentProvider";
import { InventorySettingProvider } from "context/InventorySettingProvider";
import { VolumePartnerProvider } from "context/VolumePartnerProvider";
import { PartnerProvider } from "context/PartnerProvider";

import Promotions from "pages/Admin-Marketing/Promotions"
import Analytics from "pages/Admin-Marketing/Analytics"
import Partners from "pages/Admin-Marketing/Partners"
import Referrals from "pages/Admin-Marketing/Referrals"
import OverviewPartner from "pages/Admin-Partners/Overview"
import VolumePartner from "pages/Admin-Partners/Volume-Partner"
import UserPartner from "pages/Admin-Partners/User-Partner"
import LocationPartner from "pages/Admin-Partners/Location-Partner"
import PartnerReport from "pages/Admin-Partners/Reports"
import AddPartner from "pages/Admin-Partners/Overview/AddPartner"
import EditPartner from "pages/Admin-Partners/Overview/EditPartner"
import PartnerPayment from "pages/Admin-Partners/Payments"

import { PromotionProvider } from "context/PromotionProvider";

const userRoutes = [
  { path: "/dashboard", component: <OTPDisabledMiddleware><PeriodProvider><Dashboard /></PeriodProvider></OTPDisabledMiddleware> },

  // //calendar
  // { path: "/calendar", component: <Rolemiddleware><Calendar /></Rolemiddleware> },

  // //profile
  { path: "/profile", component: <UserProfile /> },

  //Email
  // { path: "/email-inbox", component: <EmailInbox /> },
  // { path: "/email-read", component: <EmailRead /> },
  // { path: "/email-compose", component: <EmailCompose /> },

  // // Email Template
  // { path: "/email-template-alert", component: <Emailtemplatealert /> },
  // { path: "/email-template-basic", component: <Emailtemplatebasic /> },
  // { path: "/email-template-billing", component: <Emailtemplatebilling /> },

  //Charts
  { path: "/apex-charts", component: <ChartApex /> },
  { path: "/chartist-charts", component: <ChartistChart /> },
  { path: "/chartjs-charts", component: <ChartjsChart /> },
  { path: "/e-charts", component: <EChart /> },
  { path: "/sparkline-charts", component: <SparklineChart /> },

  // Icons
  { path: "/icons-dripicons", component: <IconDripicons /> },
  { path: "/icons-materialdesign", component: <IconMaterialdesign /> },
  { path: "/icons-fontawesome", component: <IconFontawesome /> },
  { path: "/icons-ion", component: <IconIon /> },
  { path: "/icons-themify", component: <ThemifyIcon /> },
  { path: "/icons-typicons", component: <TypiconsIcon /> },

  // Tables
  { path: "/tables-basic", component: <BasicTables /> },
  { path: "/tables-datatable", component: <DatatableTables /> },
  { path: "/tables-responsive", component: <ResponsiveTables /> },
  { path: "/tables-editable", component: <EditableTables /> },

  // Maps
  { path: "/maps-google", component: <MapsGoogle /> },
  { path: "/maps-vector", component: <MapsVector /> },
  { path: "/maps-leaflet", component: <MapsLeaflet /> },

  // Forms
  // { path: "/form-elements", component: <FormElements /> },
  // { path: "/form-advanced", component: <FormAdvanced /> },
  // { path: "/form-editors", component: <FormEditors /> },
  // { path: "/form-mask", component: <FormMask /> },
  { path: "/form-repeater", component: <FormRepeater /> },
  // { path: "/form-uploads", component: <FormUpload /> },
  // { path: "/form-wizard", component: <FormWizard /> },
  // { path: "/form-validation", component: <FormValidations /> },
  // { path: "/form-xeditable", component: <FormXeditable /> },

  // // Ui
  { path: "/ui-alerts", component: <UiAlert /> },
  { path: "/ui-buttons", component: <UiButtons /> },
  { path: "/ui-cards", component: <UiCards /> },
  { path: "/ui-carousel", component: <UiCarousel /> },
  { path: "/ui-colors", component: <UiColors /> },
  { path: "/ui-dropdowns", component: <UiDropdown /> },
  { path: "/ui-general", component: <UiGeneral /> },
  { path: "/ui-grid", component: <UiGrid /> },
  { path: "/ui-images", component: <UiImages /> },
  { path: "/ui-lightbox", component: <UiLightbox /> },
  { path: "/ui-modals", component: <UiModal /> },
  { path: "/ui-progressbars", component: <UiProgressbar /> },
  { path: "/ui-tabs-accordions", component: <UiTabsAccordions /> },
  { path: "/ui-typography", component: <UiTypography /> },
  { path: "/ui-video", component: <UiVideo /> },
  { path: "/ui-session-timeout", component: <UiSessionTimeout /> },
  // { path: "/ui-rating", component: <UiRating /> },
  { path: "/ui-rangeslider", component: <UiRangeSlider /> },
  { path: "/ui-utilities", component: <UiUtilities /> },
  { path: "/ui-offcanvas", component: <UiOffcanvas /> },

  // //Utility
  // { path: "/pages-starter", component: <PagesStarter /> },
  // { path: "/pages-timeline", component: <PagesTimeline /> },
  // { path: "/pages-invoice", component: <PagesInvoice /> },
  // { path: "/pages-directory", component: <PagesDirectory /> },
  // { path: "/pages-faqs", component: <PagesFaqs /> },
  // { path: "/pages-pricing", component: <PagesPricing /> },
  // { path: "/pages-gallery", component: <PagesGallery /> },
  { path: "/pages-profile", component: <PagesProfile /> },

  { path: "/uco-price", component: <OTPDisabledMiddleware> <RoleMiddleware> <PriceProvider> <FinancePrice /> </PriceProvider> </RoleMiddleware></OTPDisabledMiddleware> },
  { path: "/accounting", component: <OTPDisabledMiddleware><RoleMiddleware> <PaymentProvider> <FinancePayment /> </PaymentProvider>  </RoleMiddleware></OTPDisabledMiddleware> },
  { path: "/accounting/:id", component: <OTPDisabledMiddleware><RoleMiddleware> <PaymentProvider> <FinancePayment /> </PaymentProvider>  </RoleMiddleware></OTPDisabledMiddleware> },
  { path: "/currencies", component: <OTPDisabledMiddleware><RoleMiddleware> <CurrencyProvider> <FinanceCurrencies /> </CurrencyProvider> </RoleMiddleware> </OTPDisabledMiddleware> },
  { path: "/donation", component: <OTPDisabledMiddleware><RoleMiddleware> <DonationProvider> <Donation /> </DonationProvider>  </RoleMiddleware> </OTPDisabledMiddleware> },
  { path: "/deployments", component: <OTPDisabledMiddleware> <RoleMiddleware> <BoxProvider><Engineer /></BoxProvider> </RoleMiddleware> </OTPDisabledMiddleware> },
  { path: "/box-group", component: <OTPDisabledMiddleware> <RoleMiddleware> <GroupProvider><Group /></GroupProvider> </RoleMiddleware> </OTPDisabledMiddleware> },
  { path: "/userlist", component: <OTPDisabledMiddleware> <RoleMiddleware> <PeriodProvider><UserProvider> <UserList /> </UserProvider> </PeriodProvider> </RoleMiddleware></OTPDisabledMiddleware> },
  { path: "/userlist/:id", component: <OTPDisabledMiddleware> <RoleMiddleware> <PeriodProvider><UserProvider> <UserList /> </UserProvider> </PeriodProvider> </RoleMiddleware></OTPDisabledMiddleware> },
  { path: "/leaderboard", component: <OTPDisabledMiddleware><RoleMiddleware> <LeaderProvider> <Leaderboard /> </LeaderProvider>  </RoleMiddleware></OTPDisabledMiddleware> },
  { path: "/pn-broadcast", component: <OTPDisabledMiddleware><RoleMiddleware><NotifLogProvider> <BroadcastSupports /></NotifLogProvider></RoleMiddleware></OTPDisabledMiddleware> },
  { path: "/transactions", component: <OTPDisabledMiddleware><RoleMiddleware><PeriodProvider><TransactionProvider><Transactions /></TransactionProvider></PeriodProvider></RoleMiddleware> </OTPDisabledMiddleware> },
  { path: "/transactions/:id", component: <OTPDisabledMiddleware><RoleMiddleware><PeriodProvider><TransactionProvider><Transactions /></TransactionProvider></PeriodProvider></RoleMiddleware> </OTPDisabledMiddleware> },
  { path: "/general", component: <OTPDisabledMiddleware><RoleMiddleware><BonusTierProvider><DailyTipProvider><EducardProvider><AppSettings /></EducardProvider></DailyTipProvider></BonusTierProvider></RoleMiddleware></OTPDisabledMiddleware> },
  {
    path: "/pickups", component: <OTPDisabledMiddleware><RoleMiddleware> <Pickups /></RoleMiddleware></OTPDisabledMiddleware>
  },
  {
    path: "/pickups/:id", component: <OTPDisabledMiddleware><RoleMiddleware> <Pickups /></RoleMiddleware></OTPDisabledMiddleware>
  },
  {
    path: "/admin", component: <OTPDisabledMiddleware><RoleMiddleware><AdminProvider><MenuProvider><PermissionProvider> <AdminList /></PermissionProvider> </MenuProvider></AdminProvider></RoleMiddleware></OTPDisabledMiddleware>
  },
  {
    path: "/alerts", component: <OTPDisabledMiddleware><RoleMiddleware><Alerts /></RoleMiddleware></OTPDisabledMiddleware>
  },
  { path: "/assets", component: <OTPDisabledMiddleware><AssetProvider><BonusTierProvider><Assets /></BonusTierProvider></AssetProvider></OTPDisabledMiddleware> },
  { path: "/collections", component: <OTPDisabledMiddleware><CollectionProvider><Collections /></CollectionProvider></OTPDisabledMiddleware> },
  { path: "/orders", component: <OTPDisabledMiddleware><OrderProvider><Orders /></OrderProvider></OTPDisabledMiddleware> },
  { path: "/uco-units", component: <OTPDisabledMiddleware><UcoUnitProvider><UcoUnits /></UcoUnitProvider></OTPDisabledMiddleware> },
  { path: "/overview", component: <OTPDisabledMiddleware><OverviewProvider><Overview /></OverviewProvider></OTPDisabledMiddleware> },
  { path: "/incidents", component: <OTPDisabledMiddleware><IncidentProvider><Incidents /></IncidentProvider></OTPDisabledMiddleware> },
  { path: "/inventory-settings", component: <OTPDisabledMiddleware><InventorySettingProvider><Settings /></InventorySettingProvider></OTPDisabledMiddleware> },

  { path: "/menulist", component: <OTPDisabledMiddleware><MenuProvider> <Menus /> </MenuProvider></OTPDisabledMiddleware> },
  { path: "/partners", component: <OTPDisabledMiddleware><PromotionProvider> <Partners /> </PromotionProvider></OTPDisabledMiddleware> },
  { path: "/partners/:id", component: <OTPDisabledMiddleware><PromotionProvider> <Partners /> </PromotionProvider></OTPDisabledMiddleware> },
  { path: "/referrals", component: <OTPDisabledMiddleware><PromotionProvider> <Referrals /> </PromotionProvider></OTPDisabledMiddleware> },
  { path: "/promotions", component: <OTPDisabledMiddleware><PromotionProvider> <Promotions /> </PromotionProvider></OTPDisabledMiddleware> },
  { path: "/analytics", component: <OTPDisabledMiddleware><PromotionProvider> <Analytics /> </PromotionProvider></OTPDisabledMiddleware> },
  { path: "/partner-overview", component: <OTPDisabledMiddleware><PartnerProvider> <OverviewPartner /> </PartnerProvider></OTPDisabledMiddleware> },
  { path: "/volume-partners", component: <OTPDisabledMiddleware><VolumePartnerProvider> <VolumePartner /> </VolumePartnerProvider></OTPDisabledMiddleware> },
  { path: "/user-partners", component: <OTPDisabledMiddleware><PartnerProvider> <UserPartner /> </PartnerProvider></OTPDisabledMiddleware> },
  { path: "/user-partners/:id", component: <OTPDisabledMiddleware><PartnerProvider> <UserPartner /> </PartnerProvider></OTPDisabledMiddleware> },
  { path: "/location-partners", component: <OTPDisabledMiddleware><PartnerProvider> <LocationPartner /> </PartnerProvider></OTPDisabledMiddleware> },
  { path: "/partner-reports", component: <OTPDisabledMiddleware><PartnerProvider> <PartnerReport /> </PartnerProvider></OTPDisabledMiddleware> },
  { path: "/partner-overview/add-partner", component: <OTPDisabledMiddleware><PartnerProvider><PromotionProvider><AddPartner /></PromotionProvider></PartnerProvider></OTPDisabledMiddleware> },
  { path: "/partner-overview/edit-partner/:id", component: <OTPDisabledMiddleware><PartnerProvider><PromotionProvider><EditPartner /></PromotionProvider></PartnerProvider></OTPDisabledMiddleware> },
  { path: "/partner-payments", component: <OTPDisabledMiddleware><PartnerProvider> <PartnerPayment /> </PartnerProvider></OTPDisabledMiddleware> },
  
  // this route should be at the end of all other routes
  {
    path: "/", component: <OTPDisabledMiddleware><PeriodProvider><Dashboard /></PeriodProvider></OTPDisabledMiddleware>
  },

];

const authRoutes = [
  { path: "/logout", component: <Logout /> },
  { path: "/login", component: <Login /> },
  { path: "*", component: <Pages404 /> },
  // { path: "/trace", component: <OrderTracking /> },


  // { path: "/forgot-password", component: <ForgetPwd /> },
  // { path: "/register", component: <Register /> },

  // { path: "/pages-maintenance", component: <PagesMaintenance /> },
  // { path: "/pages-comingsoon", component: <PagesComingsoon /> },

  // { path: "/pages-500", component: <Pages500 /> },

  // // Authentication Inner
  // { path: "/pages-login", component: <Login1 /> },
  // { path: "/pages-login-2", component: <Login2 /> },
  // { path: "/pages-register", component: <Register1 /> },
  // { path: "/pages-register-2", component: <Register2 /> },
  // { path: "/page-recoverpw", component: <Recoverpw /> },
  // { path: "/page-recoverpw-2", component: <Recoverpw2 /> },
  // { path: "/pages-forgot-pwd", component: <ForgetPwd1 /> },
  // { path: "/auth-lock-screen", component: <LockScreen /> },
  // { path: "/auth-lock-screen-2", component: <LockScreen2 /> },
  // { path: "/page-confirm-mail", component: <ConfirmMail /> },
  // { path: "/page-confirm-mail-2", component: <ConfirmMail2 /> },
  // { path: "/auth-email-verification", component: <EmailVerification /> },
  // { path: "/auth-email-verification-2", component: <EmailVerification2 /> },
  // { path: "/auth-two-step-verification", component: <TwostepVerification /> },
  // { path: "/auth-two-step-verification-2", component: <TwostepVerification2 /> },
];

export { userRoutes, authRoutes };
